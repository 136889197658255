/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";

import TransactionService from "services/transactions";
import { InputTextarea } from "primereact";

const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Success", value: "success" },
  { name: "Declined", value: "declined" },
];

const Details = (props, ref) => {
  const { data, reload, toast, onCancle, setLoading } = props;

  const emptyData = {};

  const [details, setDetails] = useState<any>(emptyData);
  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      await TransactionService.changeStatusWithdraw({
        body: {
          status: details.status,
          id: data._id,
          pass: details.pass,
          note: details.note,
        },
      });
      setLoading(false);
      showToast(toast, "success", "Success");
      onCancle();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="title">User</label>
        <InputText value={details.user_id?.refer_code} disabled />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Amount</label>
        <InputText value={details.amount} disabled />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Token</label>
        <InputText value={details.token} disabled />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Network</label>
        <InputText value={details.network} disabled />
      </div>
      {details.transaction_details?.address && (
        <div className="field col-12 md:col-6">
          <label htmlFor="title">Address</label>
          <InputText value={details.transaction_details?.address} disabled />
        </div>
      )}
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Pass withdraw</label>
        <InputText
          value={details.pass}
          onChange={(e: any) => onChange("pass", e.target.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="typeField">Status</label>
        <Dropdown
          value={details.status}
          options={STATUS}
          onChange={(e: any) => onChange("status", e.value)}
          optionLabel="name"
          placeholder="Status"
          className={"w-full"}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="title">Note</label>
        <InputTextarea
          value={details.note}
          onChange={(e: any) => onChange("note", e.target.value)}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
