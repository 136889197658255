import VUpload from "components/v-upload";
import { Button } from "primereact";
import { v4 as uuidv4 } from "uuid";

const View = (props) => {
  const { slide, setSlide } = props;

  const defaultValue = {
    id: uuidv4(),
    url: "",
  };

  const handleAdd = () => {
    const _slide = slide.concat(defaultValue);
    setSlide(_slide);
  };

  const handleSub = () => {
    const _slide = slide.slice(0, slide.length - 1);
    setSlide(_slide);
  };

  const handleUpload = (file: any, id: string) => {
    const _slide = slide.map((el) => {
      if (el.id === id) return { ...el, url: file };
      else return { ...el };
    });
    setSlide(_slide);
  };

  return (
    <div className="field col-12 grid">
      <div className="col-12">
        <div className="field col-12">
          <h5>Images</h5>
          <Button
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success"
            aria-label="Search"
            onClick={(_) => handleAdd()}
          />
          <Button
            icon="bx bxs-minus-circle"
            className="p-button-rounded p-button-danger"
            aria-label="Cancel"
            onClick={(_) => handleSub()}
          />
        </div>
        <div className="field col-12 grid gri">
          {slide.length > 0 &&
            slide.map((el) => (
              <div key={el.id} className="field col-4">
                <VUpload
                  urlFile={el.url}
                  setUrlFile={(file) => handleUpload(file, el.id)}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default View;
