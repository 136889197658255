/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { formatLocalTime, showToast } from "utils/common";
import Details from "./components/Details";
import Roles from "./components/Roles";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { Dropdown, Panel } from "primereact";
import Objects from "./components/Objects";
import { MasterContext } from "contexts/MasterContext";

const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Confirmed", value: "confirmed" },
  { name: "Blocked", value: "blocked" },
];

const STATUS_OBJECT = [
  { name: "Pending", value: "pending" },
  { name: "Confirmed", value: "confirmed" },
  { name: "Reject", value: "reject" },
  { name: "User not create", value: "null" },
];

const View = () => {
  const { objects } = useContext(MasterContext);

  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: STATUS.map((s) => s.value),
    status_object: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDialogRole = useRef(null);
  const refDialogObject = useRef(null);
  const refDialogResetPassword = useRef(null);

  const refDetail = useRef(null);
  const refRole = useRef(null);
  const refObject = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await UserService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // OBJECT
  const onSubmitDialogObject = () => {
    refObject.current.submit();
  };
  const editObject = (data) => {
    setDetail({ ...data });
    refDialogObject.current.show();
  };
  const setLoadingSaveObject = (flg) => {
    refDialogObject.current.setLoading(flg);
  };
  const onCancelDialogObject = () => {
    refDialogObject.current.close();
  };

  //  ROLES
  const onSubmitDialogRole = () => {
    refRole.current.submit();
  };
  const editRole = (data) => {
    setDetail({ ...data });
    refDialogRole.current.show();
  };
  const setLoadingSaveRole = (flg) => {
    refDialogRole.current.setLoading(flg);
  };
  const onCancelDialogRole = () => {
    refDialogRole.current.close();
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await UserService.deleteUser({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "User Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const confirmResetPassword = (data) => {
    setDetail(data);
    refDialogResetPassword.current.show();
  };

  const handleResetPassword = async () => {
    try {
      await UserService.resetPasswordByAdmin(detail?._id);
      refDialogResetPassword.current.close();
      setDetail(null);
      showToast(toast, "success", "Reset password success!");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="bx bxs-user-circle text-2xl"
          className="p-button-rounded p-button-sm p-button-info mr-2"
          onClick={() => editObject(rowData)}
        />

        <Button
          icon="bx bxs-cog text-2xl"
          className="p-button-rounded p-button-sm p-button-info mr-2"
          onClick={() => editRole(rowData)}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => confirmDelete(rowData)}
        />
        <Button
          icon="pi pi-refresh"
          className="p-button-rounded p-button-sm p-button-success"
          onClick={() => confirmResetPassword(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block p-input-icon-left col-12 md:col-4">
            <i className="ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <div className="col-12 md:col-4">
            <Dropdown
              value={globalFilter.status}
              options={STATUS}
              onChange={(e: any) => onSearch("status", e.value)}
              optionLabel="name"
              placeholder="Status"
              showClear
              className={"w-full"}
            />
          </div>
          <div className="col-12 md:col-4">
            <Dropdown
              value={globalFilter.status_object}
              options={STATUS_OBJECT}
              onChange={(e: any) => onSearch("status_object", e.value)}
              optionLabel="name"
              placeholder="Status object"
              className={"w-full"}
              showClear
            />
          </div>
          <div className="col-6">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="Start Date"
              value={globalFilter.start}
              onChange={(e: any) => onSearch("start", e.value)}
              showTime
              showSeconds
              showButtonBar
            />{" "}
          </div>
          <div className="col-6">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="End Date"
              value={globalFilter.end}
              onChange={(e: any) => onSearch("end", e.value)}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
        </div>
      </div>
    </Panel>
  );

  const renderObjectType = (objectId: string) => {
    return objects.find((item) => item.id === objectId)?.name;
  };

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "300px" }}
            ></Column>
            <Column
              field="refer_code"
              header="Id"
              style={{ flexGrow: 1, flexBasis: "250px" }}
              body={(rowData) => String(rowData.refer_code || "")}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "350px" }}
            ></Column>
            <Column
              field="group"
              header="Group"
              style={{ flexGrow: 1, flexBasis: "200px" }}
            ></Column>
            <Column
              field="object_type"
              header="Object Type"
              style={{ flexGrow: 1, flexBasis: "200px" }}
              body={(rowData) => renderObjectType(rowData.object_type)}
            ></Column>
            <Column
              field="fullName"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "300px" }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="phone"
              header="Phone"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="referred_by"
              header="Sponsor"
              body={(rowData) => String(rowData.referred_by || "").slice(0, 10)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status_kyc"
              header="Status Kyc"
              body={(rowData) => (
                <span
                  className={`v-badge status-${String(
                    rowData?.status_kyc
                  )?.toLowerCase()}`}
                >
                  {String(rowData.status_kyc)}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status Object"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.object_info.status.toLowerCase()}`}
                >
                  {rowData.object_info.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="is_leader"
              header="Leader"
              sortable
              body={(rowData) => (
                <>
                  {rowData.is_leader && (
                    <i className="bx bxs-checkbox-checked text-4xl text-blue-600"></i>
                  )}
                </>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="percent_sales"
              header="Percent Sale"
              sortable
              body={(rowData) => <>{rowData.percent_sales}%</>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
          <VConfirm
            ref={refDialogResetPassword}
            message={`Are you sure you want to reset password for user?`}
            onConfirm={handleResetPassword}
          />

          <VDialog
            ref={refDialogRole}
            header="User Role"
            onSubmit={onSubmitDialogRole}
          >
            <Roles
              ref={refRole}
              toast={toast}
              user={detail}
              setLoading={setLoadingSaveRole}
              onCancel={onCancelDialogRole}
            />
          </VDialog>

          <VDialog
            ref={refDialogObject}
            header="User Object"
            onSubmit={onSubmitDialogObject}
          >
            <Objects
              ref={refObject}
              toast={toast}
              data={detail}
              reload={loadData}
              setLoading={setLoadingSaveObject}
              onCancel={onCancelDialogObject}
            />
          </VDialog>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
