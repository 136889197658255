/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { renderAmount, renderText } from "utils/render";
import debounce from "lodash.debounce";
import { Calendar, Dropdown, InputText, Panel } from "primereact";
import TradeService from "services/trades";

const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Success", value: "success" },
  { name: "Error", value: "error" },
];
const TYPES = [
  {
    label: "Buy",
    value: "buy",
  },
  {
    label: "Sell",
    value: "sell",
  },
];

const Crud = () => {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [type, setType] = useState("buy");
  // const [sum, setSum] = useState([]);

  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: "",
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 20,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter, type]);

  const loadData = async () => {
    try {
      const res: any = await TradeService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          type,
          sortField: `${lazyParams.sortField}`,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {}
  };

  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block col-12 md:col-4 p-input-icon-left">
            <i className="ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <Calendar
            className="col-6 md:col-4"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
          <Calendar
            className="col-6 md:col-4"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
          <div className="col-12 md:col-4">
            <Dropdown
              value={type}
              options={TYPES}
              onChange={(e: any) => setType(e.value)}
              optionLabel="label"
              placeholder="Type"
              className={"w-full"}
            />
          </div>
          <div className="col-12 md:col-4">
            <Dropdown
              value={globalFilter.status}
              options={STATUS}
              onChange={(e: any) => onSearch("status", e.value)}
              optionLabel="name"
              placeholder="Status"
              className={"w-full"}
            />
          </div>
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              header="Buyer"
              style={{ flexGrow: 1, flexBasis: "200px" }}
              body={(rowData) => (
                <span>{renderText(rowData?.buyer?.email, 30)}</span>
              )}
            />
            <Column
              header="Seller"
              style={{ flexGrow: 1, flexBasis: "200px" }}
              body={(rowData) => (
                <span>{renderText(rowData?.seller?.email, 30)}</span>
              )}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="from"
              header="From"
              body={(rowData) => (
                <>
                  {renderAmount(rowData.from_amount || 0)} ({rowData.from_stock}
                  )
                </>
              )}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="to"
              header="To"
              body={(rowData) => (
                <>
                  {renderAmount(rowData.to_amount || 0)} ({rowData.to_stock})
                </>
              )}
            />
            <Column
              header="Status"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            />
            <Column
              field="createdAt"
              header="Created At"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => rowData.createdAt}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
