/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "../../layouts/AppTopbar";
import { AppFooter } from "../../layouts/AppFooter";
import { AppMenu } from "../../layouts/AppMenu";
import { AppConfig } from "../../layouts/AppConfig";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { MasterContext } from "contexts/MasterContext";
import { useContext } from "react";

import Dashboard from "./dashboards";
import Empty from "./empty";
import Users from "./users";

import Uploads from "./uploads";

import Supports from "./supports";
import Balances from "./balances";

import TransactionDeposits from "./transactions/deposits";
import TransactionWithdraws from "./transactions/withdraws";
import TransactionChanges from "./transactions/changes";

import Stocks from "./stocks";
import Notifications from "./notifications";
import Allotments from "./allotments";
import StocksPrice from "./settings/stocks-price";
import Logos from "./settings/logos";

import QuestionAndAnswer from "./q&a";
import WhitePaper from "./white-paper";
import Policy from "./policy";

import Themes from "./settings/themes";
import SettingWebConfig from "./settings/web-config";
import BuySellTrade from "./trades/buy-and-sell";
import WithdrawTradeBalance from "./trades/withdraw";
import Ads from "./settings/ads";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef: any = useRef();
  const location = useLocation();
  const { auth, getStocks, getUsers } = useContext(MasterContext);

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (auth) {
      getUsers();
      getStocks();
    }
  }, [auth]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle: any) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event: any) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event: { preventDefault: () => void }) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event: { preventDefault: () => void }) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event: {
    preventDefault: () => void;
  }) => {
    mobileTopbarMenuClick = true;
    event.preventDefault();
  };

  const onMenuItemClick = (event: { item: { items: any } }) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: "Home",
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/",
          module: "overview",
        },
      ],
    },
    {
      label: "SYSTEMS",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Support",
          icon: "bx bx-support",
          to: "/supports",
          module: "support",
        },
        {
          label: "Uploads",
          icon: "bx bx-cloud-upload",
          to: "/uploads",
          module: "uploads",
        },
        {
          label: "Banners",
          icon: "pi pi-fw pi-list",
          to: "/banners",
          module: "banners",
        },
        {
          label: "Balances",
          icon: "pi pi-fw pi-tablet",
          to: "/balances",
          module: "users",
        },
        {
          label: "Q&A",
          icon: "bx bx-question-mark",
          to: "/qa",
          module: "settings",
        },
        {
          label: "Whitepaper",
          icon: "bx bx-paperclip",
          to: "/whitepaper",
          module: "settings",
        },
        {
          label: "Privacy Policy",
          icon: "pi pi-fw pi-bars",
          to: "/policy",
          module: "settings",
        },
      ],
    },
    {
      label: "USERS",
      items: [
        {
          label: "Users",
          icon: "bx bxs-user",
          to: "/users",
          badge: "10",
          module: "users",
        },

        {
          label: "Stocks",
          icon: "bx bxs-coin-stack",
          to: "/stocks",
          module: "cryptos",
        },
        {
          label: "Allotments",
          icon: "bx bx-add-to-queue",
          to: "/allotments",
          module: "allotments",
        },

        {
          label: "Transactions",
          icon: "bx bx-transfer",
          module: "transactions",
          items: [
            {
              label: "Deposits",
              icon: "bx bx-list-ul",
              to: "/transactions/deposits",
              module: "transactions",
            },
            {
              label: "Withdraws",
              icon: "bx bx-list-ul",
              to: "/transactions/withdraws",
              module: "transactions",
            },

            {
              label: "Changes",
              icon: "bx bx-list-ul",
              to: "/transactions/changes",
              module: "transactions",
            },
          ],
        },
      ],
    },
    {
      label: "STOCKS",
      icon: "pi pi-fw pi-clone",
      items: [
        {
          label: "Trades",
          icon: "bx bx-git-pull-request",
          module: "trades",
          items: [
            {
              label: "Buy and Sell",
              icon: "bx bx-list-ul",
              to: "/trades/buy-and-sell",
              module: "trades",
            },
            {
              label: "Withdraw Trade Balance",
              icon: "bx bx-list-ul",
              to: "/trades/withdraw",
              module: "trades",
            },
          ],
        },
        {
          label: "Notifications",
          icon: "bx bx-bell",
          to: "/notifications",
          module: "notifications",
        },
        {
          label: "Ads",
          icon: "pi pi-fw pi-bars",
          to: "/settings/ads",
          module: "settings",
        },
      ],
    },

    {
      label: "SETTINGS",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Web config",
          icon: "pi pi-fw pi-bars",
          to: "/settings/web_config",
          module: "settings",
        },
        {
          label: "Themes",
          icon: "pi pi-palette",
          to: "/settings/themes",
          module: "settings",
        },
        {
          label: "Stocks Price",
          icon: "pi pi-fw pi-list",
          to: "/settings/stocks-price",
          module: "settings",
        },

        {
          label: "Logo",
          icon: "bx bx-cloud-upload",
          to: "/settings/logos",
          module: "settings",
        },
        // {
        //   label: "Menus",
        //   icon: "pi pi-fw pi-bars",
        //   to: "/settings/menus",
        //   module: "settings",
        // },
      ],
    },
  ];

  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu
          model={menu}
          onMenuItemClick={onMenuItemClick}
          layoutColorMode={layoutColorMode}
        />
      </div>

      <div className="layout-main-container">
        <div className="layout-main">
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Dashboard colorMode={layoutColorMode} location={location} />
              )}
            />
            <Route path="/users" component={Users} />

            <Route path="/uploads" component={Uploads} />
            <Route path="/supports" component={Supports} />
            <Route path="/balances" component={Balances} />
            <Route path="/stocks" component={Stocks} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/allotments" component={Allotments} />

            <Route path="/qa" component={QuestionAndAnswer} />
            <Route path="/whitepaper" component={WhitePaper} />
            <Route path="/policy" component={Policy} />
            <Route path="/trades/buy-and-sell" component={BuySellTrade} />
            <Route path="/trades/withdraw" component={WithdrawTradeBalance} />

            <Route
              path="/transactions/changes"
              component={TransactionChanges}
            />
            <Route
              path="/transactions/deposits"
              component={TransactionDeposits}
            />
            <Route
              path="/transactions/withdraws"
              component={TransactionWithdraws}
            />

            <Route path="/settings/web_config" component={SettingWebConfig} />

            <Route path="/settings/stocks-price" component={StocksPrice} />
            <Route path="/settings/themes" component={Themes} />

            <Route path="/settings/logos" component={Logos} />
            <Route path="/settings/ads" component={Ads} />

            <Route path="*" component={Empty} />
          </Switch>
        </div>

        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default App;
